import React from 'react';
import css from './CustomFooter.module.css';
import { LinkedLogo, NamedLink } from '../../../../components';
import {
  fb,
  instagram,
  linkedIn,
} from '../../../CustomLandingPage/components/QuestionSection/utils';
import DesignAlertsForm from './components/DesignAlertsForm/DesignAlertsForm';

const CustomFooter = ({ copyright }) => {
  return (
    <div className={css.wrapper}>
      <div className={css.sectionTop}>
        <div className={css.sectionTopLeft}>
          <LinkedLogo
            rootClassName={css.logoLink}
            logoClassName={css.logoWrapper}
            logoImageClassName={css.logoImage}
          />

          <NamedLink name="AboutUsPage" className={css.link}>
            About us
          </NamedLink>

          <NamedLink name="HowItWorksPage" className={css.link}>
            How it works
          </NamedLink>

          <NamedLink name="SearchPage" className={css.link}>
            Marketplace
          </NamedLink>

          <NamedLink name="BlogPage" className={css.link}>
            Blog
          </NamedLink>
        </div>
        <div className={css.socialMobile}>
          <a
            className={css.facebook}
            href="https://www.facebook.com/profile.php?id=61555715476988"
            target="_blank"
          >
            {fb}
          </a>
          <a
            href="https://www.linkedin.com/company/the-modern-builder/?viewAsMember=true"
            target="_blank"
          >
            {linkedIn}
          </a>
        </div>

        <div className={css.sectionTopRight}>
          <DesignAlertsForm />
        </div>
      </div>
      <div className={css.line}> </div>
      <div className={css.sectionBottom}>
        {copyright?.content && <div className={css.bottomLeft}>{copyright?.content}</div>}

        <div className={css.bottomCenter}>
          <NamedLink name="TermsOfServicePage" className={css.link}>
            Terms and conditions
          </NamedLink>

          <NamedLink name="PrivacyPolicyPage" className={css.link}>
            Privacy policy
          </NamedLink>
        </div>

        <div className={css.bottomRight}>
          <a
            className={css.facebook}
            href="https://www.instagram.com/the.modern.builder/"
            target="_blank"
          >
            {instagram}
          </a>
          <a
            className={css.facebook}
            href="https://www.facebook.com/profile.php?id=61555715476988"
            target="_blank"
          >
            {fb}
          </a>
          <a
            href="https://www.linkedin.com/company/the-modern-builder/?viewAsMember=true"
            target="_blank"
          >
            {linkedIn}
          </a>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default CustomFooter;
