import React, { useState } from 'react';
import css from './DesignAlertsForm.module.css';
import { Button, FieldTextInput, Form } from '../../../../../../components';
import { Form as FinalForm } from 'react-final-form';
import { post } from '../../../../../../util/api';

const DesignAlertsForm = () => {
  const [done, setDone] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const handleFormSubmit = values => {
    setInProgress(true);
    const { email } = values;
    return post('/api/mail_octopus/user', {
      email,
      shouldCreateNew: true,
      subscriptionStatus: 'SUBSCRIBED',
    })
      .then(resp => {
        setDone(true);
        setInProgress(false);
      })
      .catch(e => setInProgress(false));
  };

  if (done) {
    return <p className={css.doneText}>You have been added to the design alerts list</p>;
  }

  return (
    <FinalForm
      initialValues={{}}
      onSubmit={handleFormSubmit}
      render={fieldRenderProps => {
        const { handleSubmit, values } = fieldRenderProps;
        const { email } = values;

        return (
          <Form className={css.formWrapper} onSubmit={handleSubmit}>
            <FieldTextInput
              className={css.field}
              type="text"
              id={'email'}
              name="email"
              label={'Subscribe to our design update alerts'}
              placeholder={'Type your e-mail'}
            />

            <Button
              className={css.button}
              type="submit"
              disabled={!email}
              inProgress={inProgress}
              ready={done}
            >
              Send
            </Button>
          </Form>
        );
      }}
    />
  );
};

export default DesignAlertsForm;
