export const phone = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M16.5562 12.9062L16.1007 13.359C16.1007 13.359 15.0181 14.4355 12.0631 11.4972C9.10812 8.55901 10.1907 7.48257 10.1907 7.48257L10.4775 7.19738C11.1841 6.49484 11.2507 5.36691 10.6342 4.54348L9.37326 2.85908C8.61028 1.83992 7.13596 1.70529 6.26145 2.57483L4.69185 4.13552C4.25823 4.56668 3.96765 5.12559 4.00289 5.74561C4.09304 7.33182 4.81071 10.7447 8.81536 14.7266C13.0621 18.9492 17.0468 19.117 18.6763 18.9651C19.1917 18.9171 19.6399 18.6546 20.0011 18.2954L21.4217 16.883C22.3806 15.9295 22.1102 14.2949 20.8833 13.628L18.9728 12.5894C18.1672 12.1515 17.1858 12.2801 16.5562 12.9062Z"
      fill="#F85757"
    />
  </svg>
);

export const map = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M21 16.8292V11.1625C21 10.1189 21 9.5971 20.7169 9.20427C20.4881 8.88694 20.1212 8.71828 19.4667 8.49121C19.3328 10.0974 18.8009 11.7377 17.9655 13.1734C16.9928 14.845 15.5484 16.3395 13.697 17.1472C12.618 17.6179 11.382 17.6179 10.303 17.1472C8.45164 16.3395 7.00718 14.845 6.03449 13.1734C5.40086 12.0844 4.9418 10.8778 4.69862 9.65752C4.31607 9.60117 4.0225 9.63008 3.76917 9.77142C3.66809 9.82781 3.57388 9.89572 3.48841 9.97378C3 10.4199 3 11.2493 3 12.9082V17.8379C3 18.8815 3 19.4033 3.28314 19.7961C3.56627 20.189 4.06129 20.354 5.05132 20.684L5.43488 20.8118L5.43489 20.8118C7.01186 21.3375 7.80035 21.6003 8.60688 21.6018C8.8498 21.6023 9.09242 21.5851 9.33284 21.5503C10.131 21.4347 10.8809 21.0597 12.3806 20.3099C13.5299 19.7352 14.1046 19.4479 14.715 19.3146C14.9292 19.2678 15.1463 19.2352 15.3648 19.2169C15.9875 19.1648 16.6157 19.2695 17.8721 19.4789C19.1455 19.6911 19.7821 19.7972 20.247 19.5303C20.4048 19.4396 20.5449 19.321 20.6603 19.1802C21 18.7655 21 18.1201 21 16.8292Z"
      fill="#F85757"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 2C8.68629 2 6 4.55211 6 7.70031C6 10.8238 7.91499 14.4687 10.9028 15.7721C11.5993 16.076 12.4007 16.076 13.0972 15.7721C16.085 14.4687 18 10.8238 18 7.70031C18 4.55211 15.3137 2 12 2ZM12 10C13.1046 10 14 9.10457 14 8C14 6.89543 13.1046 6 12 6C10.8954 6 10 6.89543 10 8C10 9.10457 10.8954 10 12 10Z"
      fill="#F85757"
    />
  </svg>
);

export const mail = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.17157 5.17157C2 6.34315 2 8.22876 2 12C2 15.7712 2 17.6569 3.17157 18.8284C4.34315 20 6.22876 20 10 20H14C17.7712 20 19.6569 20 20.8284 18.8284C22 17.6569 22 15.7712 22 12C22 8.22876 22 6.34315 20.8284 5.17157C19.6569 4 17.7712 4 14 4H10C6.22876 4 4.34315 4 3.17157 5.17157ZM18.5762 7.51986C18.8413 7.83807 18.7983 8.31099 18.4801 8.57617L16.2837 10.4066C15.3973 11.1452 14.6789 11.7439 14.0448 12.1517C13.3843 12.5765 12.7411 12.8449 12 12.8449C11.2589 12.8449 10.6157 12.5765 9.95518 12.1517C9.32112 11.7439 8.60271 11.1452 7.71636 10.4066L5.51986 8.57617C5.20165 8.31099 5.15866 7.83807 5.42383 7.51986C5.68901 7.20165 6.16193 7.15866 6.48014 7.42383L8.63903 9.22291C9.57199 10.0004 10.2197 10.5384 10.7666 10.8901C11.2959 11.2306 11.6549 11.3449 12 11.3449C12.3451 11.3449 12.7041 11.2306 13.2334 10.8901C13.7803 10.5384 14.428 10.0004 15.361 9.22291L17.5199 7.42383C17.8381 7.15866 18.311 7.20165 18.5762 7.51986Z"
      fill="#F85757"
    />
  </svg>
);

export const fb = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3C13.6763 3.01913 15.1906 3.43111 16.543 4.23593C17.8793 5.02469 18.9909 6.14309 19.7715 7.48417C20.5714 8.84474 20.9809 10.3683 21 12.0547C20.9525 14.3623 20.2247 16.3331 18.8166 17.9673C17.4085 19.6014 15.605 20.6124 13.7381 21V14.5305H15.5031L15.9023 11.9881H13.2296V10.3229C13.2148 9.97768 13.324 9.63856 13.5374 9.36684C13.7511 9.09438 14.1275 8.95117 14.6666 8.93722H16.2805V6.71016C16.2573 6.70271 16.0376 6.67324 15.6213 6.62177C15.1491 6.56653 14.6743 6.53702 14.199 6.53338C13.1231 6.53835 12.2723 6.84183 11.6464 7.44382C11.0205 8.04565 10.7008 8.91636 10.6873 10.056V11.9881H8.65337V14.5305H10.6873V21C8.39496 20.6124 6.59151 19.6014 5.1834 17.9673C3.77529 16.3331 3.04751 14.3623 3 12.0547C3.01901 10.3682 3.4285 8.84467 4.22846 7.48417C5.00907 6.14309 6.12067 5.0247 7.45697 4.23593C8.80936 3.43126 10.3237 3.01928 12 3Z"
      fill="#F85757"
    />
  </svg>
);

export const linkedIn = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M7.71564 8.14258H4.49689C4.35403 8.14258 4.23828 8.25838 4.23828 8.40118V18.7416C4.23828 18.8845 4.35403 19.0002 4.49689 19.0002H7.71564C7.85849 19.0002 7.97424 18.8845 7.97424 18.7416V8.40118C7.97424 8.25838 7.85849 8.14258 7.71564 8.14258Z"
      fill="#F85757"
    />
    <path
      d="M6.10835 3.00098C4.93718 3.00098 3.98438 3.95275 3.98438 5.12262C3.98438 6.29301 4.93718 7.24514 6.10835 7.24514C7.27859 7.24514 8.23061 6.29296 8.23061 5.12262C8.23066 3.95275 7.27859 3.00098 6.10835 3.00098Z"
      fill="#F85757"
    />
    <path
      d="M15.9043 7.88477C14.6116 7.88477 13.6559 8.44051 13.0763 9.07197V8.40037C13.0763 8.25757 12.9605 8.14177 12.8177 8.14177H9.73517C9.59231 8.14177 9.47656 8.25757 9.47656 8.40037V18.7408C9.47656 18.8837 9.59231 18.9994 9.73517 18.9994H12.9469C13.0897 18.9994 13.2055 18.8837 13.2055 18.7408V13.6247C13.2055 11.9007 13.6738 11.229 14.8756 11.229C16.1844 11.229 16.2884 12.3058 16.2884 13.7134V18.7409C16.2884 18.8837 16.4042 18.9995 16.547 18.9995H19.7599C19.9028 18.9995 20.0185 18.8837 20.0185 18.7409V13.069C20.0185 10.5054 19.5297 7.88477 15.9043 7.88477Z"
      fill="#F85757"
    />
  </svg>
);

export const instagram = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path
      d="M11 7.6377C9.1447 7.6377 7.6377 9.1447 7.6377 11C7.6377 12.8554 9.1447 14.366 11 14.366C12.8554 14.366 14.366 12.8554 14.366 11C14.366 9.1447 12.8554 7.6377 11 7.6377Z"
      fill="#F85757"
    />
    <path
      d="M16.0746 1.83301H5.92525C3.67025 1.83301 1.83325 3.67001 1.83325 5.92501V16.0743C1.83325 18.333 3.67025 20.1663 5.92525 20.1663H16.0746C18.3333 20.1663 20.1666 18.333 20.1666 16.0743V5.92501C20.1666 3.67001 18.3333 1.83301 16.0746 1.83301ZM10.9999 16.9397C7.72559 16.9397 5.05992 14.274 5.05992 10.9997C5.05992 7.72534 7.72559 5.06334 10.9999 5.06334C14.2743 5.06334 16.9399 7.72534 16.9399 10.9997C16.9399 14.274 14.2743 16.9397 10.9999 16.9397ZM17.0646 6.14134C16.3716 6.14134 15.8069 5.58034 15.8069 4.88734C15.8069 4.19434 16.3716 3.62967 17.0646 3.62967C17.7576 3.62967 18.3223 4.19434 18.3223 4.88734C18.3223 5.58034 17.7576 6.14134 17.0646 6.14134Z"
      fill="#F85757"
    />
  </svg>
);
